import ComponentError from "@/components/common/asyncComponents/ComponentError";
import ComponentLoader from "@/components/common/asyncComponents/ComponentLoader";
import {initApp} from "@/utils/utils";
import {defineAsyncComponent as vueDefineAsyncComponent} from 'vue'

const defineAsyncComponent = (source) => vueDefineAsyncComponent({
    loader: source,
    loadingComponent: ComponentLoader,
    errorComponent: ComponentError,
    delay: 0
});

const BannerMain = defineAsyncComponent(() => import(/* webpackPrefetch: true */'@/components/banners/BannerMain'));
const Entrance = defineAsyncComponent(() => import('@/components/entrance/Entrance'));
const Header = defineAsyncComponent(() => import(/* webpackPrefetch: true */'@/components/header/Header'));
const HeaderAlert = defineAsyncComponent(() => import(/* webpackPrefetch: true */'@/components/header/HeaderAlert'));
const ApplicationDetailsView = defineAsyncComponent(() => import('@/views/ApplicationDetails/ApplicationDetailsView'));
const ApplicationView = defineAsyncComponent(() => import('@/views/Applications/ApplicationView'));
const BlogDetailsView = defineAsyncComponent(() => import('@/views/BlogDetails/BlogDetailsView'));
const FAQView = defineAsyncComponent(() => import('@/views/FAQView/FaqView'));
const HubPageView = defineAsyncComponent(() => import('@/views/hubpage/HubPageView'));
const LocationDetailsView = defineAsyncComponent(() => import('@/views/LocationDetails/LocationDetailsView'));
const OurTeamView = defineAsyncComponent(() => import('@/views/OurTeam/OurTeamView'));
const PressReliasesView = defineAsyncComponent(() => import('@/views/PressReleses/PressReleasesView'));
const ResourcesView = defineAsyncComponent(() => import('@/views/Resources/ResoucesView'));
const searchResultView = defineAsyncComponent(() => import('@/views/SearchResult/SearchResultView'));
const AboutUsView = defineAsyncComponent(() => import('@/views/AboutUs/AboutUsView'))
const diff = defineAsyncComponent(() => import('@/components/differentiators/Diff'));
const AboutUs = defineAsyncComponent(() => import('@/components/about-us/AboutUs'));
const ApplicationGallery = defineAsyncComponent(() => import('@/components/application-details/ApplicationGallery'));
const ApplicationTestimonial = defineAsyncComponent(() => import('@/components/application-details/ApplicationTestimonial'));
const RelatedProducts = defineAsyncComponent(() => import('@/components/application-details/RelatedProducts'));
const ApplicationsCarousel = defineAsyncComponent(() => import('@/components/applications/ApplicationsCarousel'));
const BlogDetailsModal = defineAsyncComponent(() => import('@/components/blog-details/BlogDetailsModal'));
const BlogDetailsHeader = defineAsyncComponent(() => import('@/components/blog-details/BlogDetailsHeader'));
const BlogDetailsPagination = defineAsyncComponent(() => import('@/components/blog-details/BlogDetailsPagination'));
const BlogDetailsSidebar = defineAsyncComponent(() => import('@/components/blog-details/BlogDetailsSidebar'));
const BlogsListWidget = defineAsyncComponent(() => import('@/components/blogs-list/BlogListWidget'));
const BlogsList = defineAsyncComponent(() => import('@/components/blogs-list/BlogsList'));
const ButtonForModalForm = defineAsyncComponent(() => import('@/components/common/ButtonForModalForm'));
const SimplePagination = defineAsyncComponent(() => import('@/components/common/SimplePagination'));
const Contacts = defineAsyncComponent(() => import('@/components/contact-us/Contacts'));
const ContactUsForm = defineAsyncComponent(() => import('@/components/contact-us/ContactUsForm'));
const NearLocation = defineAsyncComponent(() => import('@/components/contact-us/FindNearLocation'));
const FindNearLocation = defineAsyncComponent(() => import('@/components/contact-us/FindNearLocation'));
const FAQs = defineAsyncComponent(() => import('@/components/faq-section/FAQ-section'));
const FAQ = defineAsyncComponent(() => import('@/components/faq/Faq'));
const Footer = defineAsyncComponent(() => import('@/components/footer/Footer'));
const FooterNav = defineAsyncComponent(() => import('@/components/footer/FooterNav'));
const Process = defineAsyncComponent(() => import('@/components/header-process/Process'));
const Healthcare = defineAsyncComponent(() => import('@/components/healthcare-carousel/Healthcare'));
const HubPage = defineAsyncComponent(() => import('@/components/hubpage/HubPage'));
const LocationDetails = defineAsyncComponent(() => import('@/components/location-details/LocationDetails'));
const LocationsList = defineAsyncComponent(() => import('@/components/locations/LocationsList'));
const LookingFor = defineAsyncComponent(() => import('@/components/looking-for/looking-for'));
const Mailing = defineAsyncComponent(() => import('@/components/mailing/Mailing'));
const OurTeam = defineAsyncComponent(() => import('@/components/our-team/OurTeam'));
const PressReliases = defineAsyncComponent(() => import('@/components/press-releases/press-releases'));
const ProductCategoryDetails = defineAsyncComponent(() => import('@/components/product-category-details/ProductCategoryDetails'));
const ProductsListOfCategory = defineAsyncComponent(() => import('@/components/product-list-category/ProductsListOfCategory'));
const ProductsList = defineAsyncComponent(() => import('@/components/products-list/ProductsList'));
const CardsList = defineAsyncComponent(() => import('@/components/resources/Cards-list'));
const ResourcesSearchResult = defineAsyncComponent(() => import('@/components/resources/ResourcesSearchResult'));
const ServiceCenter = defineAsyncComponent(() => import('@/components/service-center/ServiceCenter'));
const ServiceLocation = defineAsyncComponent(() => import('@/components/service-location/ServiceLocation'));
const ServicePlans = defineAsyncComponent(() => import('@/components/service-plans/ServicePlans'));
const ServicePlansSubheader = defineAsyncComponent(() => import('@/components/service-plans/ServicePlansSubheader'));
const Services = defineAsyncComponent(() => import('@/components/services/Services'));
const solutionHeader = defineAsyncComponent(() => import('@/components/solution/solution-header'));
const Testimonials = defineAsyncComponent(() => import('@/components/testimonials/Testimonials'));
const ContactDetailsMap = defineAsyncComponent(() => import('@/components/сontact-details-map/ContactDetailsMap'));
const Careers = defineAsyncComponent(() => import('@/components/careers/Careers'));

export function loadComponents() {
    initApp('.vue-entrance', Entrance)

    initApp('.vue-header-alert', HeaderAlert)
    initApp('.vue-header', Header)
    initApp('.vue-banner', BannerMain)

    initApp('.vue-blogs', BlogsList)

    initApp('.vue-search-results', searchResultView)

    initApp('.vue-applications-list', ApplicationView)
    initApp('.vue-application-details', ApplicationDetailsView)

    initApp('.vue-about-us-view', AboutUsView)
    initApp('.vue-about-us', AboutUs)

    initApp('.vue-our-team-view', OurTeamView)
    initApp('.vue-our-team', OurTeam)
    initApp('.vue-careers', Careers)

    initApp('.vue-location-details-view', LocationDetailsView)
    initApp('.vue-location-details', LocationDetails)

    initApp('.vue-resources-card-list-view', ResourcesView)
    initApp('.vue-resources-card-list', CardsList)
    initApp('.vue-resources-search-results', ResourcesSearchResult)

    initApp('.vue-faq-view', FAQView)
    initApp('.vue-faq', FAQ)

    initApp('.vue-contact-us-view-form', ContactUsForm)
    initApp('.vue-contact-us-near-location', NearLocation)

    initApp('.vue-hubpage-view', HubPageView)
    initApp('.vue-hub-page', HubPage)

    initApp('.vue-products-list-of-category', ProductsListOfCategory)

    initApp('.vue-product-category-details', ProductCategoryDetails)

    initApp('.vue-press-releases-view', PressReliasesView)
    initApp('.vue-press-releases', PressReliases)

    initApp('.vue-blog-details-view', BlogDetailsView)
    initApp('.vue-blog-details-modal', BlogDetailsModal)
    initApp('.vue-blog-details-header', BlogDetailsHeader)
    initApp('.vue-blog-details-pagination', BlogDetailsPagination)
    initApp('.vue-blog-details-sidebar', BlogDetailsSidebar)

    initWidgets()

    initApp('.vue-footer-menu', FooterNav)
    initApp('.vue-footer', Footer)
}

function initWidgets() {
    initApp('.vue-contact-us', Contacts)
    initApp('.vue-differentiators', diff)
    initApp('.vue-steps', Process)
    initApp('.vue-video-widget', solutionHeader)
    initApp('.vue-search-widget', LookingFor)
    initApp('.vue-products-categories', ProductsList)
    initApp('.vue-mailing-widget', Mailing)
    initApp('.vue-services', Services)
    initApp('.vue-faqs', FAQs)
    initApp('.contact-details-map', ContactDetailsMap)
    initApp('.vue-testimonials', Testimonials)
    initApp('.vue-services-locations', ServiceLocation)
    initApp('.vue-blogs-widget', BlogsListWidget)
    initApp('.vue-cta-gray-widget', FindNearLocation)
    initApp('.vue-cta-list-widget', CardsList)
    initApp('.vue-locations-list', LocationsList)
    initApp('.vue-healthcare', Healthcare)
    initApp('.vue-simple-pagination', SimplePagination)
    initApp('.vue-applications-widget', ApplicationsCarousel)
    initApp('.vue-application-gallery', ApplicationGallery)
    initApp('.vue-application-testimonial', ApplicationTestimonial)
    initApp('.vue-related-products', RelatedProducts)
    initApp('.vue-service-list', ServiceCenter)
    initApp('.vue-service-plan-subheader', ServicePlansSubheader)
    initApp('.vue-service-plans', ServicePlans)
    initApp('.vue-button-with-form', ButtonForModalForm)
}
